import React, { ComponentProps, useState } from 'react'

import { Button, DateRangeSelect, FormText } from '@te-digi/styleguide'

const DateRangeSelectExample = () => {
  const [value, setValue] = useState<
    ComponentProps<typeof DateRangeSelect>['value']
  >(['', ''])
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Näytä ajanjakson valinta</Button>
      <DateRangeSelect
        isOpen={isOpen}
        onChange={range => setValue(range)}
        onClose={() => setIsOpen(false)}
        value={value}
      />
      <FormText>Valittu: {JSON.stringify(value)}</FormText>
    </>
  )
}

export { DateRangeSelectExample }
